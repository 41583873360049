html, body {
    &.blocked {
        overflow: hidden;
    }
}
html {
    // -webkit-overflow-scrolling: touch;
}
body {
    font-family: $font-main;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url('/assets/images/bg-elements.jpg');
    background-repeat: repeat-y;
    background-position: top;
    background-size: 150% auto;

    @media #{$lg} {
        background-size: 100% auto;
    }
}
main {
    overflow: hidden;
}

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

// selection
::selection {
    background: $color-bg-alt;
    color: $color-text-dark;
}
