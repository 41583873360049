.form-button {
  text-align: center;
  margin-bottom: 18px;
  @media #{$md} {
    margin-bottom: 20px;
  }

  &.loading {
    .form-button {
      &__btn {
        span {
          opacity: 0;
          visibility: hidden;
        }
      }
      &__loading {
        display: block;
      }
    }

  }

  &__btn {
    @extend %button-style;
    @include transition($duration: .3s);
    text-transform: uppercase;
    background-image: linear-gradient(to bottom, $color-button-light, $color-button);
    border-radius: 20px;
    padding: 9.5px 30px;
    position: relative;
    color: $color-text-dark;

    &:hover {
      box-shadow: 0 0 5px 4px rgba($color-secondary, 0.4);
    }

    &--cancel {

      &:hover {
        box-shadow: 0 0 5px 4px rgba($color-bg, 0.4);
      }
    }
  }

  &__loading {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 20px;
    transform: translate(-50%, -50%);

    svg {
      width: 50px;
      display: block;
      height: 20px;
    }
  }

  &--last {
    margin-bottom: 0;
  }

  &--profile {
    .form-button {
      &__btn {
        min-width: 117px;
        text-transform: none;
        font-size: 15.5px;
        padding: 9.5px 20px;
      }
    }
  }

  &--cancel {
    .form-button {
      &__btn {
        background: $color-bg-first;
        color: $color-text-primary;
        border: 3px solid #878097;
        &:hover {
          box-shadow: 0 0 5px 4px rgba($color-bg-first, 0.4);
        }
      }
    }
  }
}
