@import './00-settings/colors.scss';
@import './00-settings/fonts.scss';
@import './00-settings/grid.scss';
@import './01-tools/tools.scss';
@import './01-tools/breakpoints.scss';
@import './01-tools/flex.scss';
@import './01-tools/transitions.scss';
@import './01-tools/reusable.scss';
@import './02-generic/00-reset.scss';
@import './02-generic/01-global.scss';
@import './02-generic/02-grid.scss';
@import './02-generic/03-base.scss';
@import './02-generic/04-typography.scss';
@import './02-generic/05-animations.scss';

// elements TODO: przenieść to do plików jako importy.
@import './03-elements/login.scss';
@import './03-elements/login-by-provider.scss';
@import './03-elements/login-form.scss';
@import './03-elements/custom-input.scss';
@import './03-elements/input-checkbox.scss';
@import './03-elements/form-button.scss';
@import './03-elements/input-photo.scss';
@import './03-elements/input-select.scss';

// nadpisane
@import './overwrites';

qnm-footer {
  margin-top: auto;
}

.text-center {
  text-align: center;
}
