/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a{
  color: inherit;
  text-decoration: inherit;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
body * {
    outline: none;
}

input::-ms-clear {
    display: none;
}

input, textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    margin: 0;
}

input {
    &[type="submit"] {
        cursor: pointer;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: $color-text-primary;
    // box-shadow: inset 0 0 15px 3px rgba($color-text-primary, 0.3);
    -webkit-box-shadow: 0 0 0 1000px $color-bg-second,inset;
    transition: background-color 5000s ease-in-out 0s;
}

.input-free-coins {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: $color-text-primary;
    // box-shadow: inset 0 0 15px 3px rgba($color-text-primary, 0.3);
    -webkit-box-shadow: 0 0 0 1000px $color-bg-second,inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

button {
    border: none;
    cursor: pointer;
}

.notification__paragraph-title {
  font-weight: 600 !important;
  font-size: 24px;
}

.notification__paragraph {
  line-height: 24px !important;
  font-size: 16px;
}

.notification__paragraph-bold {
  font-weight: 600 !important;
  line-height: 26px;
}
