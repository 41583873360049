.input-select {
  margin-bottom: 18px;
  position: relative;

  &.open {
    z-index: 10;
  }

  &__title {
    @extend %label;
    color: $color-text-dark;
    display: block;
    margin-left: 6.5px;
    margin-bottom: 14.5px;

    @media #{$md} {
      margin-left: 14.5px;
    }
  }

  &__item {
    @extend %input-style;
    @include flex(flex-start, center, nowrap);
    @include transition;
    position: relative;
    color: $color-text-dark;
    width: 100%;
    height: 42px;
    padding: 0 20px;
    cursor: pointer;

    &:hover {
        @include box-shadow-avatar;
      }
  }

  &__selected {
    border: solid 2px $color-border-light;
    border-radius: 20px;
    background-color: $color-bg;
    position: relative;
    z-index: 2;
    padding-right: 22px;

    @media #{$md} {
      padding-right: 28px;
    }

    &::after {
      content: '';
      position: absolute;
      right: 14.5px;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
      background-size: 6px 10px;
      background-position: bottom;
      background-repeat: no-repeat;
      height: 50%;
      width: 12px;
      background-image: url('/assets/images/i-arrow.svg');
    }
  }

  &__flag {
    margin-right: 14.5px;

    > img, svg {
      width: 16px;
      height: 9px;
      object-fit: contain;
      object-position: center;
    }
  }

  &__open {
    background-color: $color-bg;
    overflow: hidden;
    max-height: 190px;
    padding-top: 21px;
    border-radius: 0 0 30px 30px;
    border: solid 2px $color-border-light;
    border-top: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 21px;

    > input {
      @extend %input-style;
      color: $color-text-dark;
      width: calc(100% - 40px);
      height: 35px;
      border-bottom: solid 2px $color-border-light;
      margin: 5px 20px 12px 20px;
    }

    &--months {
      .input-select__list {
        max-height: 175px;
        padding-bottom: 30px;
      }
    }
  }

  &__list {
    overflow: auto;
    height: auto;
    max-height: 96px;
    position: relative;

    @media #{$lg} {
      // margin-right: 16px;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-box;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: darken($color-box, 3%);
    }

    .input-select__item {
      height: 32px;
    }
  }

  &__flex {
    @include flex(flex-start, stretch, nowrap);
    position: relative;
    z-index: 3;
  }

  &__wrap {
    position: relative;
    z-index: 1;
  }

  &__edit-user-data-modal-options {
    .ngx-select__choices {
      height: 120px !important;
    }
  }

  &__registration-complete-modal-options {
    .ngx-select__choices {
      height: 125px !important;
    }
  }

  &__input {
    width: 100%;

    > input {
      @extend %input-style;
      @include transition;
      color: $color-text-white;
      width: 100%;
      height: 42px;
      padding: 0 20px;
      border-radius: 0 20px 20px 0;
      background-color: $color-bg-first;
      border: solid 2px $color-bg-first;
      border-left: none;

      @media #{$md} {
        padding: 0 28px 0 12px;
      }

      &:focus {
      }
    }
  }

  &--phone {
    .dropdown-item{
      padding: 0 10px !important;
    }
    .ngx-select__flag{
      display: none !important;
    }
    .input-select {
      &__selected {
        border-radius: 20px 0 0 20px;
        background-color: $color-bg-first;
        border-right: none;
      }
      &__flag {
        margin-right: 8px;
      }
      &__open {
        background-color: $color-bg-first;


        > input {
          background-color: $color-bg-first;
        }
      }
      &__list {
        // margin-right: 4px;

        &::-webkit-scrollbar-thumb {
          background: $color-bg;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: darken($color-box, 3%);
        }
      }
      &__wrap {
        width: 160px;
        flex: 0 0 160px;
        max-width:100px;
      }
    }
  }

  &--basic {
    .input-select {
      &__item {
        padding: 0 22px;

        @media #{$md} {
          padding: 0 28px;
        }
      }
    }
  }
}

.input-select {
  &.valid {
    .ngx-select__toggle {
      border-color: $color-error !important;
    }

    .input-select__input {
      input {
        border-color: $color-error !important;
      }
    }
  }
}
