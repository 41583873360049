%headline-1 {
  font-family: $font-main;
  font-size: 48.5px * 0.744 * 0.8;
  font-weight: bold;
  line-height: 1;

  @media #{$sm} {
    font-size: 48.5px * 0.8 * 0.8;
  }
  @media #{$md} {
    font-size: 48.5px * 0.85 * 0.8;
    line-height: 1.2;
  }
  @media #{$lg} {
    font-size: 48.5px * 0.9 * 0.8;
  }
  @media #{$xl} {
    font-size: 48.5px * 0.8;
  }
}

%headline-2 {
  font-family: $font-main;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
}

%headline-3 {
  font-family: $font-main;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
}

%label {
  font-family: $font-main;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}

%p {
  font-family: $font-main;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
}

%p-small {
  font-family: $font-main;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
}

%input-style {
  font-family: $font-main;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
}

%input-large-style {
  font-family: $font-main;
  font-size: 24px * 0.8;
  font-weight: normal;
  line-height: normal;
}

%button-style {
  font-family: $font-main;
  font-size: 15.5px * 0.8;
  letter-spacing: 1.16px;
  font-weight: bold;
  line-height: normal;
}

%valid-style {
  font-family: $font-main;
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
}

%user-label-style {
  font-family: $font-main;
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;

  @media #{$md} {
    font-size: 9.5px;
  }
}

%coin-value-style {
  font-family: $font-main;
  font-size: 21px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;

  @media #{$md} {
    font-size: 22px;
  }
  @media #{$lg} {
    font-size: 24px;
  }
}

%switch-style {
  font-family: $font-main;
  font-size: 12.5px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: normal;

  @media #{$md} {
    font-size: 13.5px;
  }
  @media #{$lg} {
    font-size: 14.5px;
  }
}

%button-lobby-style {
  font-family: $font-main;
  font-size: 10px;
  letter-spacing: 0.5px;
  font-weight: bold;
  line-height: normal;

  @media #{$md} {
    letter-spacing: 1.16px;
    font-size: 12px;
  }
  @media #{$lg} {
    font-size: 13px;
  }
}

%p-table {
  font-family: $font-main;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;

  @media #{$md} {
    font-size: 13px;
  }
}

%coins-style-table {
  font-family: $font-main;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;

  @media #{$md} {
    font-size: 19px;
  }
}

%tab-style {
  font-family: $font-main;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal;

  @media #{$md} {
    font-size: 16px;
  }
  @media #{$lg} {
    font-size: 18px;
  }
  @media #{$xl} {
    font-size: 19px;
  }
}

%profile-label {
  font-family: $font-main;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal;
}

%profile-input {
  font-family: $font-main;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1;
}

%profile-points {
  font-family: $font-main;
  font-size: 18.5px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
}

%label-join-style {
  font-family: $font-main;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: .68px;
}

%label-tournament-waiting-room-style {
  font-family: $font-main;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: .68px;
}

%waiting-label {
  font-family: $font-main;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;

  @media #{$md} {
    font-size: 16px;
  }
  @media #{$lg} {
    font-size: 18px;
  }
}

%waiting-info {
  font-family: $font-main;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
}

%counter-val {
  font-family: $font-main;
  font-size: 47.5px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;

  @media #{$sm} {
    font-size: 60px;
  }
  @media #{$md} {
    font-size: 90px;
  }
  @media #{$lg} {
    font-size: 120px;
  }
  @media #{$xl} {
    font-size: 167.5px;
  }
}

%counter-round {
  font-family: $font-main;
  font-size: 40.5px;
  font-weight: bold;
  font-style: italic;
  line-height: 1;
  letter-spacing: normal;
}

%game-value {
  font-family: $font-main;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  font-size: 21px;

  @media #{$xl} {
    font-size: 33.5px;
  }
}
%game-question {
  font-family: $font-main;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: normal;
  user-select: none;

  @media #{$md} {
    font-size: 18px;
  }
  @media #{$md} {
    font-size: 20px;
  }
  @media #{$lg} {
    line-height: 1.2;
    font-size: 24px;
  }
  @media #{$xl} {
    font-size: 30px;
  }
}
%game-win-title {
  font-family: $font-main;
  font-size: 30px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
}
%game-letter {
  font-family: $font-main;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;

  @media #{$md} {
    font-size: 20px;
  }
  @media #{$lg} {
    font-size: 26px;
  }
  @media #{$xl} {
    font-size: 30px;
  }
}
%game-answer {
  font-family: $font-main;
  font-size: 26px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
}
%game-answer-scale {
  font-family: $font-main;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;

  @media #{$md} {
    font-size: 20px;
  }
  @media #{$lg} {
    font-size: 24px;
  }
  @media #{$xl} {
    font-size: 26px;
  }
}
%game-answer-smaller {
  font-family: $font-main;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.22;
  letter-spacing: normal;
}
%game-answer-smaller-scale {
  font-family: $font-main;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.22;
  letter-spacing: normal;

  @media #{$md} {
    font-size: 14px;
  }
  @media #{$lg} {
    font-size: 16px;
  }
  @media #{$xl} {
    font-size: 18px;
  }
}
%player-box-label {
  font-family: $font-main;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1;

  @media #{$md} {
    font-size: 15px;
  }
  @media #{$lg} {
    font-size: 16px;
  }
  @media #{$xl} {
    font-size: 18px;
  }
}

%game-user-label {
  font-family: $font-main;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal;

  @media #{$lg} {
    font-size: 12px;
  }
}

%game-points-name {
  font-family: $font-main;
  font-size: 8.5px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;

  @media #{$md} {
    font-size: 12px;
  }
}

%game-points-info {
  font-family: $font-main;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: normal;

  @media #{$md} {
    font-size: 14px;
  }
}

%game-seconds {
  font-family: $font-main;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;

  @media #{$md} {
    // font-size: 16px;
  }
  @media #{$lg} {
    font-size: 17px;
  }
  @media #{$xl} {
    font-size: 18.5px;
  }
}
%game-timer {
  font-family: $font-main;
  font-size: 21px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;

  @media #{$md} {
    font-size: 22px;
  }
  @media #{$xl} {
    font-size: 24px;
  }
}
%game-bet-value {
  font-family: $font-main;
  font-size: 12.5px;
  font-weight: 600;
  line-height: 0.95;
  letter-spacing: normal;

  @media #{$sm} {
    font-size: 14px;
  }
  @media #{$md} {
    font-size: 20px;
  }
  @media #{$lg} {
    font-size: 24px;
  }
  @media #{$xl} {
    font-size: 27px;
  }
}
