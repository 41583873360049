.input-checkbox {
  &.valid {
    .input-checkbox__label {
      .input-checkbox__content {
        // color: $color-error;
      }

      > input {
        border: solid 2px $color-error;
      }
    }
  }
}
