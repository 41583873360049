.login-form {
  margin: 24px 0 20px 0;

  @media #{$md} {
    margin-top: 32px;
  }

  @media #{$lg} {
    margin-top: 40px;
    margin-bottom: 80px;
  }

  .custom-input {
    input {
      background-color: $color-bg-first;
    }
  }

  &__box {
    margin: 0 auto;
    max-width: 334px;
    padding: 44px 0 20px 0;
    background-color: $color-bg-second;
    border: 2px solid $color-border;
    border-radius: 30px;
    overflow: hidden;
    position: relative;

    &--registration-complete {
      padding: 10px 0 45px;
      border-radius: 30px;
    }
  }

  &__borders {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    border-radius: 30px;

    &::before, &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 11px;
    }
    &::before {
      top: 0;
      background-color: $color-bg-second;
    }
    &::after {
      bottom: 0;
      background-color: $color-bg-second;
    }
  }
  .input-select{
    &__title{
      color: $color-text-grey;
      text-transform: uppercase;
      font-size: 12px;
    }
  }

  &__title {
    @extend %headline-1;
    text-align: center;
    color: $color-text-primary;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0 18px;
    font-size: 25px;

    @media #{$md} {
      padding: 0 20px;
    }
  }

  &__form-section-title {
    font-family: $font-main;
    text-align: center;
    color: $color-text-white;
    background: none;
    width: 100%;
    padding: 0 0 20px 14px;
    font-size: 16px;
    font-weight: bold;

    @media (max-width: $max-xs) {
      font-size: 11px;
      letter-spacing: .82px;
      padding: 0 0 20px 8px;
    }
  }

  &__form-section-free-coins {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-main;
    text-align: left;
    color: $color-text-primary;
    background: none;
    width: 100%;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;

    img {
      margin-right: 15px;
      width: 50px;
    }

    p {
      padding-bottom: 10px;
    }

    @media (max-width: $max-xs) {
      font-size: 14px;
      letter-spacing: .82px;
      padding: 0 0 20px 8px;
      margin-bottom: 0;

      img {
        width: 30px;
        margin-right: 10px;
      }

      p {
        padding-bottom: 0;
      }
    }
  }

  &__form {
    padding: 0 6.5px;

    @media #{$sm} {
      padding: 0 18px;
    }
    @media #{$md} {
      padding: 0 24px;
    }
    @media #{$lg} {
      padding: 0 32px;
    }
    @media #{$xl} {
      padding: 0 40px;
    }
  }

  &__desc {
    @extend %p;
    margin-left: 6.5px;
    margin-bottom: 14.5px;
    color: $color-text-grey;

    &--no-margin{
      margin-bottom: 0;
    }

    a {
      @include transition;
      color: $color-button;

      &:hover {
      }
    }

    &--center {
      margin-left: 0;
      text-align: center;

      a {
        color: $color-secondary;

        &:hover {
        }
      }

      button {
        color: $color-secondary;

        &:hover {
        }
      }
    }
  }

  &__by-provider-container {
    margin: 0 0 20px;
  }

  &__provider-btn {
    @extend %p;
    color: $color-text-white !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border: 1px solid $color-border-light;
    border-radius: 22px;
    padding: 10px 0 10px 15px;
    margin-bottom: 10px;
    background: none;
    &:hover {
      border: 1px solid $color-border;
    }
  }

  &__login-switch {
    position: relative;
    @include flex(center, stretch, nowrap);
    margin: 0 auto;
    width: 100%;
    max-width: 450px;
    padding-top: 6px;
    border-radius: 28.5px 28.5px 0 0;
    height:60px;

    &__wrap {
      position: relative;
      z-index: 1;
      flex: 1 1 auto;
      cursor: pointer;
      width: 50%;
      background: $color-box;
      border-radius: 30px 30px 0 0;
      border-top: 3px solid $color-text-primary;

      &--inactive {
        border-top: 3px solid $color-text-grey-light;
      }
    }

    &__name {
      font-family: $font-main;
      text-align: center;
      color: $color-text-primary;
      background: none;
      width: 100%;
      padding-top: 10px;
      font-size: 16px;
      font-weight: bold;

      &--inactive {
        color: $color-text-grey-light;
      }

      @media (max-width: $max-xs) {
        font-size: 11px;
        letter-spacing: .82px;
      }
    }
  }
  .form-button{
    &__btn{
      padding: 22px 0 20px;
      border-radius: 27px;
      width: 100%;
    }
  }
}

// Powiedzmy zze pracowalem juz 20 godzin
