/* ==========================================================================
   Fonts
   ========================================================================== */

/* Import
   ========================================================================== */

@font-face {
  font-family: 'Prompt';
  src: url('/assets/fonts/Prompt-Regular.eot');
  src: url('/assets/fonts/Prompt-Regular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Prompt-Regular.woff2') format('woff2'),
      url('/assets/fonts/Prompt-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Prompt';
  src: url('/assets/fonts/Prompt-Medium.eot');
  src: url('/assets/fonts/Prompt-Medium.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Prompt-Medium.woff2') format('woff2'),
      url('/assets/fonts/Prompt-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Prompt';
  src: url('/assets/fonts/Prompt-SemiBold.eot');
  src: url('/assets/fonts/Prompt-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Prompt-SemiBold.woff2') format('woff2'),
      url('/assets/fonts/Prompt-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Prompt';
  src: url('/assets/fonts/Prompt-Bold.eot');
  src: url('/assets/fonts/Prompt-Bold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Prompt-Bold.woff2') format('woff2'),
      url('/assets/fonts/Prompt-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Prompt';
  src: url('/assets/fonts/Prompt-BoldItalic.eot');
  src: url('/assets/fonts/Prompt-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Prompt-BoldItalic.woff2') format('woff2'),
      url('/assets/fonts/Prompt-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

/* Variables
   ========================================================================== */

    $font-main: 'Prompt', sans-serif;


