.login-by-provider {
  margin: 24px 0 20px 0;

  @media #{$md} {
    margin-top: 32px;
  }
  @media #{$lg} {
    margin-top: 40px;
    margin-bottom: 80px;
  }

  &__box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: 450px;
    padding: 32px 0 40px 0;
    background-color: $color-box;
    border-radius: 30px;
    // overflow: hidden;
    position: relative;

    @media #{$lg} {
      padding: 40px 0;
    }

  }
  &__borders {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    border-radius: 30px;

    &::before, &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 11px;
    }
    &::before {
      top: 0;
      background-color: $color-text-primary;
    }
    &::after {
      bottom: 0;
      background-color: $color-secondary;
    }
  }

  &__title {
    @extend %headline-3;
    text-align: center;
    color: $color-text-primary;
    margin-bottom: 20px;
    padding: 0 18px;

    @media #{$md} {
      margin-bottom: 32px;
      padding: 0 20px;
    }
  }

  &__spinner {
    width: 20px;
    height: 20px;
    border: 3px solid $color-secondary;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

}
