/* ==========================================================================
   Colors
   ========================================================================== */

/* General
   ========================================================================== */

$color-primary: #342a4f;
$color-secondary: #86d11b;


/* Background
   ========================================================================== */

$color-bg: #ffffff;
$color-bg-alt: #dfdfdf;
$color-bg-quiz-coins: #1f1635;
$color-bg-free-coins: #021823;
$color-bg-first: #1f1635;
$color-bg-input:#1f1830;
$color-bg-second: #342a4f;
$color-bg-third: #493d67;

/* Text
   ========================================================================== */

$color-text-primary: #ffffff;
$color-text-dark: #342a4f;
$color-text-dark-alt: #05073b;
$color-text-alt: #86d11b;
$color-text-white: #ffffff;
$color-text: #000;
$color-text-grey: #786d91;
$color-text-grey-light: #c2c2c2;

/* Other
  ========================================================================== */

$color-border: #86d11b;
$color-border-alt: #ffffff;
$color-border-light: #e9e1e1;
$color-box: #f5f2f9;
$color-button-light: #86d11b;
$color-button: #86d11b;
$color-error: #f44040;
$color-warning: #f44040;
$color-counter-round: #9291ff;
$color-input-dark:#1f1830;



// lobby colors
$color-fc: #029fa8;
$color-fc-2: #025c61;
$color-fc-3: #003236;
$color-fc-text: #01b7c2;

$color-qc: #342a4f;
$color-qc-3: #493d67;
$color-qc-2: #1f1830;
$color-qc-text: #72bd08;
$color-qc-alt: #e2c13d;

// join colors
$color-buyin: #86d11b;
$color-prizepool: #86d11b;
$color-waiting: #86d11b;
$color-ranking: #86d11b;
$color-start-date: #86d11b;
$color-category: #86d11b;
$color-free-coins-border: #01CAD7;
$color-free-coins-border-alternative: #0391A3;

// game
$color-game: #86d11b;
$color-bg-game: #060734;
$color-game-correct: #02b554;
$color-game-wrong: #cf0101;
$color-game-win: #86d11b;

// tournament
$color-tournament-dark: #003236;
$color-tournament-player-active: #EF5DA8;
$color-tournament-player-quiz-coin: #6787fd;
$color-tournament-player-free-coin: #0391A3;
$color-tournament-prizepool: #FD8C42;

// rankings
$color-ranking-free-points: #003236;
$color-ranking-day: #fb7d3b;
$color-ranking-week: #009F53;
$color-ranking-month: #0099DB;

// calendars
$color-calendar-bg: #5a58fb;
$color-calendar-selected: #fd8b42;
$color-calendar-button: #fd8b42;

// tournament ladder
$color-ladder-qc: $color-bg-first;
$color-ladder-qc-light: $color-bg-third;
$color-ladder-text-white: #ffffff;
$color-ladder-text-qc: #111386;
$color-ladder-star: #F27131;
$color-ladder-accent: #fd8b42;
$color-ladder-bg-accent: #3341B6;
$color-ladder-border-accent: #1F2599;

$color-fc-2: #025c61;
$color-ladder-fc: #003236;
$color-ladder-text-fc: #003236;
$color--ladder-fc-light: #01b7c2;

// lobby buttons shadow
$color-lobby-btn-shadow: #0b0e6d;
