.custom-input {
  margin-bottom: 22px;

  .input-free-coins {
    > input  {
      color: $color-fc-3 !important;
      border: solid 2px $color-fc !important;

      &:focus {
        box-shadow: inset 0 0 15px 3px rgba($color-fc-2, 0.3) !important;
        border-color: $color-fc-2 !important;
      }

      &::after {
        background-image: url('/assets/images/i-arrow-free-coins.svg') !important;
      }

      &:hover, &.active {
        box-shadow: inset 0 0 15px 3px rgba($color-fc, 0.3);
      }
    }

    &--with-prefix-prefix {
      width: 10% !important;
      border-radius: 20px 0 0 20px !important;
      border-right: unset !important;
      background: $color-bg-alt !important;
      padding: 0 !important;
      text-align: center !important;
    }

    .custom-input__input--with-prefix-value {
      width: 90% !important;
      border-radius: 0 20px 20px 0 !important;
      border-left: unset !important;
    }

    .custom-input__input--with-prefix-focus {
      border-color: $color-fc-2 !important;
    }
  }

  &.valid {
    .custom-input__input {
      > input {
        border: 2px solid $color-error !important;
      }

      > textarea {
        border-color: $color-error !important;
      }

      &::after {
        background-image: url('/assets/images/i-error-color.svg');
      }
    }

    .ngx-select__toggle {
      border-color: $color-error !important;
    }

    .input-select__input {
      input {
        border-color: $color-error !important;
      }
    }

    &--alternative {
      .custom-input__input {
        &::after {
          background-image: url('/assets/images/i-error-color.svg');
          top: 45px;
          right: 36px;
        }
      }
    }
  }

  &.success {
    .custom-input__input {
       > input {
        border-color: $color-border;
      }
      &::after {
        background-image: url('/assets/images/i-success-color.svg');
      }
    }
  }

  > label {
    @extend %label;
    color: $color-text-grey;
    display: block;
    margin-left: 6.5px;
    margin-bottom: 11px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;

    @media #{$md} {
      margin-left: 14.5px;
    }
  }

  &--label-light > label {
    color: #fff !important;
  }

  &__input {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: 14.5px;
      top: 50%;
      transform: translateY(-50%);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 50%;
      width: 12px;
    }

    > input {
      @extend %input-style;
      @include transition;
      position: relative;
      color: $color-text-primary;
      background-color: $color-bg-first;
      width: 100%;
      height: 42px;
      border-radius: 20px;
      padding: 0 22px;

      @media #{$md} {
        padding: 0 28px;
      }

    }

    > textarea {
      @extend %input-style;
      @include transition;
      position: relative;
      color: $color-text-primary;
      width: 100%;
      border-radius: 20px;
      padding: 0 22px;

      @media #{$md} {
        padding: 0 28px;
      }
    }

    &--with-prefix-prefix {
      width: 10% !important;
      border-radius: 20px 0 0 20px !important;
      border-right: unset !important;
      background: $color-bg-alt !important;
      padding: 0 !important;
      text-align: center !important;
    }

    &--with-prefix-value {
      width: 90% !important;
      border-radius: 0 20px 20px 0 !important;
      border-left: unset !important;
    }

    &--with-prefix-focus {
      border-color: $color-border !important;
    }
  }

  &__valid {
    @extend %valid-style;
    color: $color-error;
    margin-top: 6.5px;
    margin-left: 6.5px;
    margin-bottom: 6.5px;

    &--text-left {
      text-align: left;
    }

    @media #{$md} {
      margin-left: 14.5px;
    }

    a {
      @include transition;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--password {
    .custom-input__input {
      > input {
        @extend %input-large-style;
        font-size: 16px;
      }
    }
  }

  &--last {
    margin-bottom: 6.5px;

    @media #{$md} {
      margin-bottom: 12px;
    }
  }
}
