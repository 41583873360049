/* ==========================================================================
   Grid
   ========================================================================== */

/* Gutter
   ========================================================================== */

    $grid-gutter: 15px;
    $grid-gutter-container: 15px;


/* Container
   ========================================================================== */

    $grid-container: 100%;
    $grid-container-sm: 540px;
    $grid-container-md: 720px;
    $grid-container-lg: 960px;
    $grid-container-xl: 1320px;


/* Breakpoints
   ========================================================================== */

    $min-sm: 576px;
    $min-md: 768px;
    $min-lg: 992px;
    $min-xl: 1366px;

    $max-xs: 575px;
    $max-sm: 767px;
    $max-md: 991px;
   //  $max-lg: 1289px;
    $max-lg: 1379px;
