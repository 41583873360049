/*
 * Transitions
 *
 * .usage {
 *    @include transition([property],[duration],[easing]);
 * }
 */

@mixin transition($property: all, $duration: .2s, $ease: ease-in-out){
    -webkit-transition: $property $duration $ease;
    -moz-transition: $property $duration $ease;
    -o-transition: $property $duration $ease;
    transition: $property $duration $ease;
}
