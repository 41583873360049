.login {
  position: relative;
  // background-image: url('/assets/images/bg-elements.jpg');
  // background-repeat: repeat-y;
  // background-position: top;
  // background-size: 150% auto;

  // @media #{$lg} {
  //   background-size: 100% auto;
  // }
}
