.input-checkbox {
  margin-bottom: 28px;

  &__label {
    @include flex(flex-start, flex-start, nowrap);
    position: relative;
    cursor: pointer;

    > input {
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
      border: solid 2px $color-border;
      border-radius: 7px;
      background-color: $color-bg;
      background-size: 10.5px 9.6px;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 12px;
      cursor: pointer;

      &:checked {
        background-image: url('/assets/images/i-check-color.svg');
      }
    }
  }

  &__content {
    @extend %p;
    color: $color-text-primary;
    margin-top: 2px;

    a {
      @include transition;
      text-decoration: underline;

      &:hover {
        color: $color-secondary;
      }
    }
  }
}
