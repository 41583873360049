@mixin box-shadow-primary {
  box-shadow: inset 0 0 15px 3px rgba($color-text-primary, 0.3);
}
@mixin box-shadow-avatar {
  box-shadow: inset 0 0 15px 3px rgba($color-border, 0.3);
}
@mixin box-shadow-input {
  box-shadow: inset 0 0 15px 3px rgba($color-bg-second, 0.6);
}
@mixin box-shadow-tournament-free-coins {
  box-shadow: inset 0 0 15px 3px rgba($color-tournament-player-free-coin, 0.3);
}
@mixin box-shadow-lobby($color) {
  box-shadow: inset 0 0 15px 3px rgba($color, 0.5);
}
@mixin box-shadow-points($color) {
  box-shadow: inset 0 0 30px 3px rgba($color, 0.5);
}

@mixin button-gradient {
  background-image: linear-gradient(to bottom, $color-button-light, $color-button);
}

@mixin button-lobby-gradient {
  background-color: #86d11b;
}

@mixin user-profile-gradient {
  background-color: #e1f5f0;
}

@mixin switch-game-fc {
  background-image:  #564c71;
}
@mixin switch-game-qc {
  background: #564c71;
}

@mixin box-shadow-waiting($color) {
  box-shadow: inset 0 0 15px 3px rgba($color, 0.3);
}

@mixin game-background {
  background-image: linear-gradient(to top, #e3e9fe, $color-bg);
}

@mixin game-label-background {
  background-image: linear-gradient(to bottom, #c20567, #e01387);
}
