.input-photo {
  position: relative;
  margin-bottom: 32px;

  > label {
    cursor: pointer;
    display: inline-block;
  }
  
  &__name {
    @extend %label;
    color: $color-text-dark;
    margin-left: 6.5px;
    margin-bottom: 14.5px;
    
    @media #{$md} {
      margin-left: 14.5px;
    }
  }
  
  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }

  &__preview {
    @include box-shadow-avatar;
    width: 120px;
    height: 120px;
    position: relative;
    border: solid 2px $color-border;
    border-radius: 25px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      object-fit: cover;
      object-position: center;

    }
  }

  &__add {
    @extend %input-large-style;
    @include transition;
    position: absolute;
    border: solid 2px rgba($color-border, .38);
    border-radius: 999px;
    width: 26px;
    height: 26px;
    right: 9px;
    top: 9px;
    color: $color-border;
    cursor: pointer;

    &::after {
      content: '+';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      line-height: 0;
      letter-spacing: 0;
      text-align: center;
    }

    &:hover {
      @include box-shadow-avatar;
      border-color: $color-border;
    }
  }
}