@keyframes spin-scale {
0% {
    transform: rotate(0deg) scale(1);
}
50% {
    transform: rotate(180deg) scale(1.1);
}
100% {
    transform: rotate(360deg) scale(1);
}
}

@keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes title-animation {
    0% {
        opacity: 1;
    }
    45% {
        opacity: 0;
    }
    55% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
//
//@keyframes circleCounter {
//  from {
//    stroke-dashoffset: 0;
//  }
//  to {
//    stroke-dashoffset: 310;
//  }
//}

@keyframes scaleIcons {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1.12);
  }
}

@keyframes starsAnimation {
  from {
    transform: scale(0.95) rotate(-3deg);
  }
  to {
    transform: scale(1.12) rotate(3deg);
  }
}

@keyframes lightsAnimation {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .2;
    transform: scale(1.5);
  }
}

@keyframes lightsAnimationSmall {
  from {
    opacity: 1;
    //transform: scale(1);
  }
  to {
    opacity: .2;
    //transform: scale(1.3);
  }
}
